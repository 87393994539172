import { API } from 'o365-modules';
import Crc32 from './lib.crc32.js';
import { dataUtils } from 'o365-utils';
import UploadQueue from './lib.UploadQueue.ts'
import type { IChunkUploaderOptions, IFileUploadOptions } from "./Types.ts";

const uploadQueue: UploadQueue = new UploadQueue(1);

export default class ChunkUploader {
    private _viewName: string | null = null;
    private _xhrs: Set<XMLHttpRequest> = new Set();
    private _chunkSize: number = 40 * 1024 * 1024;
    private _maxConcurrentUploads: number = 6;
    private _queue: Array<any> = [];
    private _chunkUrl: string = '/api/file/chunkupload2';
    private _customChunkUrl: string|null = null;
    private _uploadCanceled: boolean = false;
    private _uploadComplete: any;
    private _uploadFailed: any;
    private _reusedFileData:any|null = null;
    private skipXhrHeader: boolean;
    private _chunkInitUrl:string|null = null;
    

    
    useNTHeader: boolean = true;

    useGetProgress: boolean = true;

    private _uploads: Map<number, number | undefined> = new Map();

    constructor(pOptions: IFileUploadOptions) {
        if (pOptions.chunkUrl) {
           
            if (pOptions.chunkUrl != "/api/file/chunkupload") {
                this._customChunkUrl = pOptions.chunkUrl;
                this._chunkUrl = pOptions.chunkUrl;
                this.useGetProgress = false;
            }
        }
        this.skipXhrHeader = pOptions.skipXhrHeader ?? false;

        if(pOptions['chunkInitUrl']) this._chunkInitUrl = pOptions['chunkInitUrl'];



        if (pOptions["viewName"])
            this._viewName = pOptions["viewName"];
    }


    async processChunks(pOptions: IChunkUploaderOptions){
        ///api/file/chunkupload/upload
        if(!this._customChunkUrl)
            this._chunkUrl = '/api/file/chunkupload/upload';

        if(pOptions['chunkInitUrl']) this._chunkInitUrl = pOptions['chunkInitUrl'];

        let vProgressMap: any = {};
        this._reset();
        let uploadComplete = this._setStartPromise();
        const vUploadRef = this.useGetProgress ? await this._initUpload(pOptions) : { UploadRef: window.crypto['randomUUID']() };
        pOptions['uploadRef'] = vUploadRef['uploadRef'];
        if (this.useGetProgress)
            this._calculateCrc32(pOptions.file).then((pCrc: any) => {
                this._setCrc({ ...pOptions, fileCRC: pCrc }).then(res => {
                    console.log("reuse", this._reusedFileData, this._uploadCanceled)
                    if (this._uploadCanceled) return;
                    if (res.action == "Completed") {
                        //finish uploadComplete
                        this._reusedFileData = res;

                        vProgressMap = { 0: pOptions.file.size };
                        if (pOptions.onProgress) pOptions.onProgress.call(this, { start: 0, loaded: pOptions.file.size, file: pOptions.file })
                        this._uploadComplete();
                        this.abort();

                    }
                });
            })

        const chunksCount = Math.ceil(pOptions.file.size / this._getChunkSize(pOptions.file.size));
        
        const vOnProgress = pOptions.onProgress;

        pOptions.onProgress = (pRes: any) => {
            vProgressMap[pRes.start] = pRes.loaded;

            if (vOnProgress) vOnProgress.call(this, { loaded: Object.values(vProgressMap).reduce((a, b) => a + b, 0), file: pRes.file })
        }
       

        this._queue = Array.from({ length: chunksCount - 1 }, (_, i) => { return { chunk: i, status: 'waiting' } });
        if (chunksCount > 1) {

            for (let i = 0; i < (this.useGetProgress ? Math.min(this._maxConcurrentUploads, chunksCount - 1) : 1); i++) {
                this._runUploadQueue(pOptions);
            }
            await uploadComplete;
        }

        if (this._uploadCanceled) return null;

        if ( this._reusedFileData) return this._creatReturn(this._reusedFileData)
        

        const vRes: any = await this._uploadChunk(pOptions, chunksCount - 1);
        if (vOnProgress) vOnProgress.call(this, { loaded: pOptions.file.size, file: pOptions.file })
        if ( this._reusedFileData) return this._creatReturn(this._reusedFileData)

        if(vRes && vRes.length && vRes.row[0]["FileRef"])
        vRes["FileRef"] = vRes.row[0]["FileRef"];
        vRes["ResponseData"] = vRes.row[0];
        return vRes;
    }

    _creatReturn(resp:any){
        return {
            FileRef:resp.row.length?resp.row[0]["FileRef"]:null,
            ResponseData:resp.row.length?resp.row[0]:null
        }
    }

    async upload(pOptions: IChunkUploaderOptions) {
        let vProgressMap: any = {};
        let vReuseFileRef: any = null;
        this._reset();
        let uploadComplete = this._setStartPromise();

        if (this.useGetProgress)
            this._calculateCrc32(pOptions.file).then((pCrc: any) => {
                this._getUploadProgress({ ...pOptions, fileCRC: pCrc }).then(res => {
                    if (this._uploadCanceled) return;
                    if (res.action == "ReuseFileRef") {
                        //finish uploadComplete

                        vReuseFileRef = res.fileRef;

                        vProgressMap = { 0: pOptions.file.size };
                        if (pOptions.onProgress) pOptions.onProgress.call(this, { start: 0, loaded: pOptions.file.size, file: pOptions.file })
                        this._uploadComplete();
                        this.abort();

                    }
                });
            })

        const chunksCount = Math.ceil(pOptions.file.size / this._getChunkSize(pOptions.file.size));
        const vUploadRef = this.useGetProgress ? await this._getUploadProgress(pOptions) : { UploadRef: window.crypto['randomUUID']() };
        const vOnProgress = pOptions.onProgress;

        pOptions.onProgress = (pRes: any) => {
            vProgressMap[pRes.start] = pRes.loaded;

            if (vOnProgress) vOnProgress.call(this, { loaded: Object.values(vProgressMap).reduce((a, b) => a + b, 0), file: pRes.file })
        }
        pOptions['uploadRef'] = vUploadRef['UploadRef'];

        this._queue = Array.from({ length: chunksCount - 1 }, (_, i) => { return { chunk: i, status: 'waiting' } });
        if (chunksCount > 1) {

            for (let i = 0; i < (this.useGetProgress ? Math.min(this._maxConcurrentUploads, chunksCount - 1) : 1); i++) {
                this._runUploadQueue(pOptions);
            }
            await uploadComplete;
        }

        if (this._uploadCanceled) return null;

        if (vReuseFileRef) {
            return {
                FileRef: vReuseFileRef
            }
        }

        const vRes: any = await this._uploadChunk(pOptions, chunksCount - 1);
        if (vOnProgress) vOnProgress.call(this, { loaded: pOptions.file.size, file: pOptions.file })
        if (vReuseFileRef) {//for small files
            return {
                FileRef: vReuseFileRef
            }
        }
        vRes["FileRef"] = vRes["fileRef"];
        vRes["ResponseData"] = vRes;
        return vRes;
    }

    private _reset() {

        this._uploads.clear();
        this._reusedFileData = null;
        this._queue = [];
    }

    private _setStartPromise() {
        return new Promise((resolve, reject) => {
            this._uploadComplete = resolve;
            this._uploadFailed = reject;
        });
    }

    abort(pUser: boolean = false) {
        //  if(this._uploadComplete) this._uploadComplete();
        if (pUser) this._uploadCanceled = true;
        this._xhrs.forEach(xh => {
            xh.abort();
        });
    }

    private async _runUploadQueue(pOptions: IChunkUploaderOptions) {

        if (!this._queue.find(x => x.status == "waiting")) {
            if (this._queue.find(x => x.status == "running")) return;
            return this._uploadComplete();
        }
        const nextchunk = this._queue.find(x => x.status == "waiting");
        nextchunk.status = "running";

        try {
            const res: any = await this._uploadChunk(pOptions, nextchunk.chunk)
            nextchunk.status = "done";
            if (res && res.hasOwnProperty('uploadRef')) {
                pOptions['uploadRef'] = res['uploadRef'];
            }
            await this._runUploadQueue(pOptions);
        }
        catch (ex) {
            this.abort();
            this._uploadFailed(ex);
        }

    }

    private async _uploadChunk(pOptions: IChunkUploaderOptions, pChunkIndex: number = 0) {
        const vChunkSize = this._getChunkSize(pOptions.file.size);
        let vStart = pChunkIndex * vChunkSize;
        let vEnd = Math.min(vStart + vChunkSize, pOptions.file.size);
        return this._sliceAndUpload(pOptions, vStart, vEnd);

    }

    public getInitUploadUrl(pOptions: IChunkUploaderOptions){
        if(this._chunkInitUrl) return this._chunkInitUrl+`${pOptions.data && pOptions.data.values && pOptions.data.values.PrimKey?'/'+pOptions.data.values.PrimKey:''}`;

        return `/api/file/chunkupload/initiate/${this._viewName}${pOptions.data && pOptions.data.values && pOptions.data.values.PrimKey?'/'+pOptions.data.values.PrimKey:''}`;
    }

    private async _initUpload(pOptions: IChunkUploaderOptions) {
        await uploadQueue.getTicket();
        const res = await API.requestPost(this.getInitUploadUrl(pOptions), JSON.stringify({
            ...pOptions.data,
            FileName: pOptions.file.name,
            FileSize: pOptions.file.size,
          //  ViewName: this._viewName,
        }));
        uploadQueue.decrementActiveUploads();
        uploadQueue.processTicketQueue();

        return res;
    }
    private async _setCrc(pOptions: IChunkUploaderOptions) {
        if(!pOptions.uploadRef){
            console.warn("Upload ref is not defined",pOptions)
            return;
        }
        return await API.requestPost(`/api/file/chunkupload/setcrc32/${pOptions.uploadRef}`, JSON.stringify({
            FileName: pOptions.file.name,
            FileSize: pOptions.file.size,
            ViewName: this._viewName,
            FileCRC: pOptions.fileCRC,
            UploadRef: pOptions.uploadRef
        }));
    }



    private async _getUploadProgress(pOptions: IChunkUploaderOptions) {
       // if (this._getChunkSize(pOptions.file.size) > pOptions.file.size) {
           // return { UploadRef: window.crypto['randomUUID']() };
        //}
        return await API.requestPost("/nt/api/file/uploadprogress", JSON.stringify({
            FileName: pOptions.file.name,
            FileSize: pOptions.file.size,
            ViewName: this._viewName,
            FileCRC: pOptions.fileCRC,
            UploadRef: pOptions.uploadRef
        }));
    }


    private async _sliceAndUpload(pOptions: IChunkUploaderOptions, pStart: number, pEnd: number) {
        const vHeaders = new Map<string, any>();
        const vFormData = new FormData();
        let vUrl = this._chunkUrl; // todo (vv): change url
        if (pOptions.uploadRef) {
            vUrl += "/" + pOptions.uploadRef;
        }
        vFormData.append("File", this._slice(pOptions.file, pStart, pEnd), pOptions.file.name);
        vHeaders.set('Custom-Content-Range', `bytes ${pStart}-${pEnd - 1}/${pOptions.file.size}`);
        if (this._uploads.has(pStart)) {
            vHeaders.set('X-File-CRC', this._uploads.get(pStart));
        }


        //console.log(pStart,pEnd-1);
        return await this._uploadFile(vUrl, vFormData, (e: ProgressEvent) => {
            if (pOptions.onProgress) {
                pOptions.onProgress.call(this, {
                    loaded: e.loaded,
                    start: pStart,
                    end: pEnd
                })
            }
        }, vHeaders);
    }

    private _uploadFile(pUrl: string, pFormData: any, pOnProgress: Function | null = null, pHeaders: Map<string, any> | null = null) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            this._xhrs.add(xhr);
            xhr.open('POST', pUrl);
            xhr.setRequestHeader('Accept', 'application/json');
            if (!this.skipXhrHeader) {
                xhr.setRequestHeader('X-O365-XHR', 'true');
            }
            if (this.useNTHeader) {
                xhr.setRequestHeader('X-NT-API', 'true');
            }


            xhr.upload.addEventListener("progress", (e) => {
                // console.log("onProgress:",e.loaded);
                if (pOnProgress) {

                    pOnProgress.call(this, e);
                }

            }, true);

            xhr.onload = (e) => {

                if (xhr.status === 200) {
                    this._xhrs.delete(xhr);
                    //  if(e.constructor == ProgressEvent && pOnProgress){
                    //console.log("oncompleteted",e.loaded);
                    //pOnProgress.call(this, e);
                    // }
                    try {
                        resolve(this._onCompleteEvent(e));
                    } catch (ex) {
                        reject(new Error('Could not parse response'));
                    }
                }else if(xhr.responseText && xhr.responseText.indexOf("No upload found with uploadRef") > -1){
                    window.setTimeout(()=>{
  
                        xhr.abort();
                        this._xhrs.delete(xhr);
                        console.log("abort", this._reusedFileData)
                        if(!this._reusedFileData){
                            reject(this._onErrorEvent(e));
                        }else{
                            resolve("OK")
                        }
                    },1000)
                
                   // return resolve("FileRef reused");

                    
                
                } else {
          
                    this._xhrs.delete(xhr);
                    reject(this._onErrorEvent(e));
                }
            };


            xhr.onabort = () => {
                this._xhrs.delete(xhr);
                if (this._uploadCanceled)
                    return reject("Upload was cancelled by user");

                resolve("Upload was cancelled by user");
            };
            xhr.onerror = (e) => {
                this._xhrs.delete(xhr);
                reject(this._onErrorEvent(e));
            };
            xhr.ontimeout = (e) => {
                console.warn("timeout", e);
                this._xhrs.delete(xhr);
                reject(this._onErrorEvent(e));
            };

            if (pHeaders) {
                pHeaders.forEach((val, key) => {
                    xhr.setRequestHeader(key, val);
                })
            }

            xhr.send(pFormData);
        })


    }

    private _slice(file: File, start: number, end: number) {
        const slice = file['mozSlice'] ? file['mozSlice'] :
            file['webkitSlice'] ? file['webkitSlice'] :
                file.slice ? file.slice : 'noop';
        return slice.bind(file)(start, end);
    }


    private _onCompleteEvent(e: ProgressEvent) {
        return e.target ? JSON.parse(e.target['response']) : e.target;
    }
    private _onErrorEvent(e: ProgressEvent) {
        if (dataUtils.isJsonString(e.target['response'])) {
            return JSON.parse(e.target['response']).error;
        } else if (e.target['response']) {
            return e.target['response'];
        } else {
            return "Unspecified error";

        }

    }

    private _getChunkSize(pSize: number) {
        const mb = 1024 * 1024;

        if (pSize < 10 * mb) return 1 * mb;
        if (pSize < 20 * mb) return 2 * mb;
        if (pSize < 30 * mb) return 3 * mb;
        if (pSize < 40 * mb) return 4 * mb;
        if (pSize < 50 * mb) return 5 * mb;
        if (pSize < 60 * mb) return 6 * mb;
        if (pSize < 70 * mb) return 7 * mb;
        if (pSize < 80 * mb) return 8 * mb;
        if (pSize < 90 * mb) return 9 * mb;
        if (pSize < 100 * mb) return 10 * mb;
        if (pSize < 200 * mb) return 20 * mb;
        if (pSize < 300 * mb) return 30 * mb;
        //if(pSize < 1000 * mb) return 40 * mb;

        return this._chunkSize;

    }

    private async _calculateCrc32(pFile: File) {
        const vThat = this;
        return new Promise(resolve => {
            var reader = new FileReader();
            var crc32 = new Crc32();
            var vChunksize = vThat._getChunkSize(pFile.size),

                crc32Update: any,
                //checksum = [], not sure why is need, maybe if to use async
                chunker = function () {
                    let i = 0,
                        start = i,
                        stop = Math.min(start + vChunksize, pFile.size),
                        fileSlicer;
                    var checker = function () {

                        i++;
                        start = i * vChunksize;

                        if (start > pFile.size) {
                            crc32Update.finalize();
                            resolve(crc32Update << 0)
                            return;
                        }
                        stop = Math.min(start + vChunksize, pFile.size);
                        readBlock(start, stop, pFile);
                    };

                    var readBlock = function (start: number, stop: number, pFile: File) {

                        fileSlicer = pFile.slice(start, stop);

                        reader.onloadend = function () {
                            crc32Update = crc32.update(this.result);

                            //if(crc32Update)
                            vThat._uploads.set(start, crc32Update.crc << 0)
                            checker();
                        }
                        reader.readAsArrayBuffer(fileSlicer);
                    };

                    readBlock(start, stop, pFile);
                };
            chunker();
        });
    }
}